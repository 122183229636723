import { useAuth } from "@cpchem/azure-auth-react";
import { Avatar } from "@cpchem/covalence-ui";
import { HistoryLogViewTestIds } from "@pages/tag-management-page/test-ids";
import React, { useEffect, useState } from "react";
import { getGraphUserPhoto } from "../../../../app/components/graph-photo/graph-photo";
import { getInitialsFromName } from "../../../../app/components/template-app-bar/avatar-content/user-initials";
import "./styles.scss";

export function isInDevEnvironment(env: string): boolean {
    return env.toUpperCase() === "DEVELOPMENT";
}

export const RequestorView = () => {
    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");
    const { currentAccount } = useAuth({});

    async function loadPhoto() {
        if (profilePhoto === null) {
            setProfilePhoto(await getGraphUserPhoto(profilePhoto));
        }
        if (userInitials === "") {
            const parsedInitials = getInitialsFromName(currentAccount);
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    useEffect(() => {
        loadPhoto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="requestor-persona">
                <div
                    className="requestor-header"
                    data-testid={HistoryLogViewTestIds.RequestorHeader}
                >
                    Requestor
                </div>
                <div className="requestor-avatar">
                    {profilePhoto && (
                        <Avatar
                            alt={userInitials}
                            src={profilePhoto || ""}
                            className="avatar"
                            data-testid={HistoryLogViewTestIds.RequestorAvatar}
                        />
                    )}
                    {!profilePhoto && (
                        <Avatar
                            alt={userInitials}
                            initials={userInitials}
                            className="avatar"
                            data-testid={HistoryLogViewTestIds.RequestorAvatar}
                        />
                    )}
                    <p
                        className="requestor-name"
                        data-testid={HistoryLogViewTestIds.RequestorName}
                    >
                        {currentAccount?.name}
                    </p>
                </div>
            </div>
        </>
    );
};
