import { useAuth } from "@cpchem/azure-auth-react";
import { Avatar } from "@cpchem/covalence-ui";
import { HistoryLogViewTestIds } from "@pages/tag-management-page/test-ids";
import { Activity } from "@services/hermes/model";
import { TimeDifferenceFromNow } from "@utilities/time-difference-from-now";
import React, { useEffect, useState } from "react";
import { getGraphUserPhoto } from "../../../../app/components/graph-photo/graph-photo";
import { getInitialsFromName } from "../../../../app/components/template-app-bar/avatar-content/user-initials";
import { LogCommentView } from "../log-comment-view";
import "./styles.scss";

interface HistoryLogViewProps {
    changeLogHistory: Activity[];
}
export const HistoryLogView = ({ changeLogHistory }: HistoryLogViewProps) => {
    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");
    const { currentAccount } = useAuth({});
    async function loadPhoto() {
        if (profilePhoto === null) {
            const photo = await getGraphUserPhoto(profilePhoto);
            console.log(photo);
            setProfilePhoto(photo);
        }
        if (userInitials === "") {
            const parsedInitials = getInitialsFromName(currentAccount);
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    useEffect(() => {
        loadPhoto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className="history-log-list"
                data-testid={HistoryLogViewTestIds.HistoryLogList}
            >
                {changeLogHistory.map((logHistory) => {
                    return (
                        <div className="history-log" key={logHistory.id}>
                            <div className="history-log-avatar-comment">
                                <div className="history-log-avatar">
                                    {profilePhoto && (
                                        <Avatar
                                            alt={userInitials}
                                            src={profilePhoto || ""}
                                            className="avatar"
                                            testId={
                                                HistoryLogViewTestIds.HistoryLogAvatar
                                            }
                                        />
                                    )}
                                    {!profilePhoto && (
                                        <Avatar
                                            alt={userInitials}
                                            initials={userInitials}
                                            className="avatar"
                                            testId={
                                                HistoryLogViewTestIds.HistoryLogAvatar
                                            }
                                        />
                                    )}
                                </div>
                                <LogCommentView activity={logHistory} />
                            </div>
                            <p
                                className="history-log-modified-last"
                                data-testid={
                                    HistoryLogViewTestIds.HistoryLogModifiedLast
                                }
                            >
                                {TimeDifferenceFromNow(
                                    logHistory.commentedDate
                                )}
                            </p>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
