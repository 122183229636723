/* eslint-disable complexity */
import { DataGridColumnSortState } from "@components/display/base-header-display/constants";
import { SelectOption } from "@cpchem/covalence-ui";
import { Activity, IngestedTag, Tag, TagRequest } from "@services/hermes/model";
import { sortValue } from "@utilities/api/helper";
import { hasCheckedFilter } from "@utilities/filters/has-checked-filter";
import { hasCheckedFilterForRequests } from "@utilities/filters/has-checked-filter-for-requests";
import { resetFilterList } from "@utilities/filters/reset-filter-list";
import React, {
    Dispatch,
    ReactNode,
    createContext,
    useContext,
    useReducer
} from "react";
import { FilterListItem } from "../models/filters";

interface TagManagementState {
    currentPage: number;
    rowsToDisplay: number;
    totalIngestedTagCount: number;
    searchFieldName: string;
    searchValue: string;
    ingestedTags: IngestedTag[];
    sortValue: sortValue;
    siteFilters: FilterListItem[];
    sourceSystemFilters: FilterListItem[];
    sourceFrequencyFilters: FilterListItem[];
    aggregationTypeFilters: FilterListItem[];
    statusFilters: FilterListItem[];
    hasActiveFilter: boolean;
    myRequests: TagRequest[];
    myRequestsStatus: string;
    myApprovals: TagRequest[];
    myApprovalsStatus: string;
    totalPendingRequestCount: number;
    totalCompleteRequestCount: number;
    totalPendingApprovalCount: number;
    totalCompleteApprovalCount: number;
    siteSelectOptions: SelectOption[];
    sourceSystemSelectOptions: SelectOption[];
    tagNameSelectOptions: SelectOption[];
    tagNamesForSiteAndSource: string[];
    isTagSearchErrorDisplayed: boolean;
    pullFrequencySelectOptions: SelectOption[];
    frequencyModeSelectOptions: SelectOption[];
    tagRequest: TagRequest;
    requestLogHistory: Activity[];
    tagLogHistory: Activity[];
    newTag: Tag;
    confirmAll: string | undefined;
    removedTagsSwitchStatus: string;
    uploadedFileName: string;
    selectedSite: string | undefined;
    selectedSource: string | undefined;
    selectedTagName: string;
    scrollToElementId?: string;
}

interface TagManagementProviderProps {
    children: ReactNode;
}

type TagManagementAction =
    | { type: "SET_CURRENT_PAGE"; payload: number }
    | { type: "SET_ROWS_TO_DISPLAY"; payload: number }
    | { type: "SET_SEARCH_FIELD_NAME"; payload: string }
    | { type: "SET_OrderBy_TO_DISPLAY"; payload: sortValue }
    | { type: "SET_SEARCH_VALUE"; payload: string }
    | {
          type: "SET_INGESTED_TAGS_DATA";
          payload: {
              totalIngestedTagCount: number;
              ingestedTags: IngestedTag[];
          };
      }
    | {
          type: "SET_SITE_FILTER";
          payload: {
              siteFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_SOURCE_SYSTEM_FILTER";
          payload: {
              sourceSystemFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_SOURCE_FREQUENCY_FILTER";
          payload: {
              sourceFrequencyFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_AGGREGATION_TYPE_FILTER";
          payload: {
              aggregationTypeFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_STATUS_FILTER";
          payload: {
              statusFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_ALL_FILTERS";
          payload: {
              siteFilters: FilterListItem[];
              sourceSystemFilters: FilterListItem[];
              sourceFrequencyFilters: FilterListItem[];
              aggregationTypeFilters: FilterListItem[];
              statusFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_MY_REQUESTS_FILTERS";
          payload: {
              siteFilters: FilterListItem[];
              sourceSystemFilters: FilterListItem[];
          };
      }
    | {
          type: "SET_MY_APPROVALS_FILTERS";
          payload: {
              siteFilters: FilterListItem[];
              sourceSystemFilters: FilterListItem[];
          };
      }
    | {
          type: "RESET_BADGE";
          payload: boolean;
      }
    | {
          type: "SET_MY_REQUESTS_DATA";
          payload: {
              totalPendingRequestCount: number;
              totalCompleteRequestCount: number;
              myRequests: TagRequest[];
          };
      }
    | {
          type: "SET_MY_APPROVALS_DATA";
          payload: {
              totalPendingApprovalCount: number;
              totalCompleteApprovalCount: number;
              myApprovals: TagRequest[];
          };
      }
    | {
          type: "SET_MY_REQUESTS_STATUS";
          payload: {
              myRequestsStatus: string;
          };
      }
    | {
          type: "SET_MY_APPROVALS_STATUS";
          payload: {
              myApprovalsStatus: string;
          };
      }
    | {
          type: "SET_SITE_SELECT_OPTIONS";
          payload: {
              siteSelectOptions: SelectOption[];
          };
      }
    | {
          type: "SET_SOURCE_SYSTEM_SELECT_OPTIONS";
          payload: {
              sourceSystemSelectOptions: SelectOption[];
          };
      }
    | {
          type: "SET_TAG_NAME_SELECT_OPTIONS";
          payload: {
              tagNameSelectOptions: SelectOption[];
          };
      }
    | {
          type: "SET_TAG_NAMES_FOR_SITE_AND_SOURCE";
          payload: {
              tagNamesForSiteAndSource: string[];
          };
      }
    | {
          type: "SET_IS_TAG_SEARCH_ERROR_DISPLAYED";
          payload: {
              isTagSearchErrorDisplayed: boolean;
          };
      }
    | {
          type: "SET_PULL_FREQUENCY_SELECT_OPTIONS";
          payload: {
              pullFrequencySelectOptions: SelectOption[];
          };
      }
    | {
          type: "SET_FREQUENCY_MODE_SELECT_OPTIONS";
          payload: {
              frequencyModeSelectOptions: SelectOption[];
          };
      }
    | {
          type: "SET_TAG_REQUEST_DATA";
          payload: {
              tagRequest: TagRequest;
          };
      }
    | {
          type: "SET_REQUEST_LOG_HISTORY_DATA";
          payload: {
              requestLogHistory: Activity[];
          };
      }
    | {
          type: "SET_TAG_LOG_HISTORY_DATA";
          payload: {
              tagLogHistory: Activity[];
          };
      }
    | {
          type: "SET_NEW_TAG_DATA";
          payload: {
              newTag: Tag;
          };
      }
    | {
          type: "SET_CONFIRM_ALL";
          payload: {
              confirmAll: string | undefined;
          };
      }
    | {
          type: "SET_REMOVED_TAGS_SWITCH_STATUS";
          payload: {
              removedTagsSwitchStatus: string;
          };
      }
    | {
          type: "SET_UPLOADED_FILE_NAME";
          payload: {
              uploadedFileName: string;
          };
      }
    | {
          type: "SET_SELECTED_SITE";
          payload: {
              selectedSite: string | undefined;
          };
      }
    | {
          type: "SET_SELECTED_SOURCE";
          payload: {
              selectedSource: string | undefined;
          };
      }
    | {
          type: "SET_SELECTED_TAG_NAME";
          payload: {
              selectedTagName: string;
          };
      }
    | {
          type: "SET_SCROLL_TO_ELEMENT_ID";
          payload: {
              scrollToElementId: string;
          };
      };

interface TagManagementContextType {
    state: TagManagementState;
    dispatch: Dispatch<TagManagementAction>;
}
const defaultSortValue: sortValue = {
    column: "Site",
    sort: DataGridColumnSortState.ASC,
    type: "string"
};

export const defaultNewTag: Tag = {
    id: 1,
    site: "",
    source: "",
    tagName: "",
    engUnits: "",
    sourceFrequency: "",
    aggregationType: "",
    frequencyMode: "",
    pullFrequency: "",
    enabled: false,
    tagStatus: "",
    comments: null,
    requestedBy: "",
    requestedDate: null,
    modifiedBy: "",
    modifiedDate: null,
    commentCount: 0
};

export const defaultTagRequest: TagRequest = {
    id: 0,
    requestName: "",
    requestStatus: "",
    requestDetails: "",
    requestReason: "",
    site: "",
    source: "",
    requestedBy: "",
    requestedDate: null,
    modifiedBy: "",
    modifiedDate: null,
    feedbackNote: "",
    approvedBy: "",
    items: [],
    requestedByEmail: null,
    modifiedByEmail: null,
    approvedByEmail: null
};

const initialState: TagManagementState = {
    currentPage: 1,
    rowsToDisplay: 10,
    totalIngestedTagCount: 100,
    searchFieldName: "",
    searchValue: "",
    ingestedTags: [],
    sortValue: defaultSortValue,
    siteFilters: [],
    sourceSystemFilters: [],
    sourceFrequencyFilters: [],
    aggregationTypeFilters: [],
    statusFilters: [],
    hasActiveFilter: false,
    myRequests: [],
    myRequestsStatus: "pending",
    myApprovals: [],
    myApprovalsStatus: "pending",
    totalPendingRequestCount: 0,
    totalCompleteRequestCount: 0,
    totalPendingApprovalCount: 0,
    totalCompleteApprovalCount: 0,
    siteSelectOptions: [],
    sourceSystemSelectOptions: [],
    tagNameSelectOptions: [],
    tagNamesForSiteAndSource: [],
    isTagSearchErrorDisplayed: false,
    pullFrequencySelectOptions: [],
    frequencyModeSelectOptions: [],
    tagRequest: defaultTagRequest,
    newTag: defaultNewTag,
    confirmAll: undefined,
    removedTagsSwitchStatus: "Off",
    uploadedFileName: "",
    selectedSite: "",
    selectedSource: "",
    selectedTagName: "",
    requestLogHistory: [],
    tagLogHistory: [],
    scrollToElementId: ""
};

const TagManagementContext = createContext<TagManagementContextType>({
    state: initialState,
    dispatch: () => null
});

const TagManagementReducer = (
    state: TagManagementState,
    action: TagManagementAction
): TagManagementState => {
    switch (action.type) {
        case "SET_CURRENT_PAGE":
            return { ...state, currentPage: action.payload };
        case "SET_ROWS_TO_DISPLAY":
            return {
                ...state,
                rowsToDisplay: action.payload,
                currentPage: initialState.currentPage
            };
        case "SET_SEARCH_FIELD_NAME":
            return { ...state, searchFieldName: action.payload };
        case "SET_SEARCH_VALUE":
            return {
                ...state,
                searchValue: action.payload,
                rowsToDisplay: initialState.rowsToDisplay,
                currentPage: initialState.currentPage
            };
        case "SET_INGESTED_TAGS_DATA":
            return {
                ...state,
                totalIngestedTagCount: action.payload.totalIngestedTagCount,
                ingestedTags: action.payload.ingestedTags
            };
        case "SET_OrderBy_TO_DISPLAY":
            return { ...state, sortValue: action.payload };
        case "SET_SITE_FILTER":
            return {
                ...state,
                siteFilters: action.payload.siteFilters,
                hasActiveFilter: hasCheckedFilter(
                    action.payload.siteFilters,
                    state.sourceSystemFilters,
                    state.sourceFrequencyFilters,
                    state.aggregationTypeFilters,
                    state.statusFilters
                )
            };
        case "SET_SOURCE_SYSTEM_FILTER":
            return {
                ...state,
                sourceSystemFilters: action.payload.sourceSystemFilters,
                hasActiveFilter: hasCheckedFilter(
                    state.siteFilters,
                    action.payload.sourceSystemFilters,
                    state.sourceFrequencyFilters,
                    state.aggregationTypeFilters,
                    state.statusFilters
                )
            };
        case "SET_SOURCE_FREQUENCY_FILTER":
            return {
                ...state,
                sourceFrequencyFilters: action.payload.sourceFrequencyFilters,
                hasActiveFilter: hasCheckedFilter(
                    state.siteFilters,
                    state.sourceSystemFilters,
                    action.payload.sourceFrequencyFilters,
                    state.aggregationTypeFilters,
                    state.statusFilters
                )
            };
        case "SET_AGGREGATION_TYPE_FILTER":
            return {
                ...state,
                aggregationTypeFilters: action.payload.aggregationTypeFilters,
                hasActiveFilter: hasCheckedFilter(
                    state.siteFilters,
                    state.sourceSystemFilters,
                    state.sourceFrequencyFilters,
                    action.payload.aggregationTypeFilters,
                    state.statusFilters
                )
            };
        case "SET_STATUS_FILTER":
            return {
                ...state,
                statusFilters: action.payload.statusFilters,
                hasActiveFilter: hasCheckedFilter(
                    state.siteFilters,
                    state.sourceSystemFilters,
                    state.sourceFrequencyFilters,
                    state.aggregationTypeFilters,
                    action.payload.statusFilters
                )
            };
        case "SET_ALL_FILTERS":
            return {
                ...state,
                siteFilters: action.payload.siteFilters,
                sourceSystemFilters: action.payload.sourceSystemFilters,
                sourceFrequencyFilters: action.payload.sourceFrequencyFilters,
                aggregationTypeFilters: action.payload.aggregationTypeFilters,
                statusFilters: action.payload.statusFilters,
                hasActiveFilter: hasCheckedFilter(
                    action.payload.siteFilters,
                    action.payload.sourceSystemFilters,
                    action.payload.sourceFrequencyFilters,
                    action.payload.aggregationTypeFilters,
                    action.payload.statusFilters
                )
            };
        case "SET_MY_REQUESTS_FILTERS":
            return {
                ...state,
                siteFilters: action.payload.siteFilters,
                sourceSystemFilters: action.payload.sourceSystemFilters,
                hasActiveFilter: hasCheckedFilterForRequests(
                    action.payload.siteFilters,
                    action.payload.sourceSystemFilters
                )
            };
        case "SET_MY_APPROVALS_FILTERS":
            return {
                ...state,
                siteFilters: action.payload.siteFilters,
                sourceSystemFilters: action.payload.sourceSystemFilters,
                hasActiveFilter: hasCheckedFilterForRequests(
                    action.payload.siteFilters,
                    action.payload.sourceSystemFilters
                )
            };
        case "SET_MY_REQUESTS_DATA":
            return {
                ...state,
                totalPendingRequestCount:
                    action.payload.totalPendingRequestCount,
                totalCompleteRequestCount:
                    action.payload.totalCompleteRequestCount,
                myRequests: action.payload.myRequests
            };
        case "SET_MY_APPROVALS_DATA":
            return {
                ...state,
                totalPendingApprovalCount:
                    action.payload.totalPendingApprovalCount,
                totalCompleteApprovalCount:
                    action.payload.totalCompleteApprovalCount,
                myApprovals: action.payload.myApprovals
            };
        case "SET_MY_REQUESTS_STATUS":
            return {
                ...state,
                myRequestsStatus: action.payload.myRequestsStatus,
                searchValue: initialState.searchValue,
                siteFilters: resetFilterList(state.siteFilters),
                sourceSystemFilters: resetFilterList(state.sourceSystemFilters),
                sourceFrequencyFilters: resetFilterList(
                    state.sourceFrequencyFilters
                ),
                aggregationTypeFilters: resetFilterList(
                    state.aggregationTypeFilters
                ),
                statusFilters: resetFilterList(state.statusFilters),
                hasActiveFilter: initialState.hasActiveFilter
            };
        case "SET_MY_APPROVALS_STATUS":
            return {
                ...state,
                myApprovalsStatus: action.payload.myApprovalsStatus,
                searchValue: initialState.searchValue,
                siteFilters: resetFilterList(state.siteFilters),
                sourceSystemFilters: resetFilterList(state.sourceSystemFilters),
                sourceFrequencyFilters: resetFilterList(
                    state.sourceFrequencyFilters
                ),
                aggregationTypeFilters: resetFilterList(
                    state.aggregationTypeFilters
                ),
                statusFilters: resetFilterList(state.statusFilters),
                hasActiveFilter: initialState.hasActiveFilter
            };
        case "SET_SITE_SELECT_OPTIONS":
            return {
                ...state,
                siteSelectOptions: action.payload.siteSelectOptions
            };
        case "SET_SOURCE_SYSTEM_SELECT_OPTIONS":
            return {
                ...state,
                sourceSystemSelectOptions:
                    action.payload.sourceSystemSelectOptions
            };
        case "SET_TAG_NAME_SELECT_OPTIONS":
            return {
                ...state,
                tagNameSelectOptions: action.payload.tagNameSelectOptions
            };
        case "SET_TAG_NAMES_FOR_SITE_AND_SOURCE":
            return {
                ...state,
                tagNamesForSiteAndSource:
                    action.payload.tagNamesForSiteAndSource
            };
        case "SET_IS_TAG_SEARCH_ERROR_DISPLAYED":
            return {
                ...state,
                isTagSearchErrorDisplayed:
                    action.payload.isTagSearchErrorDisplayed
            };
        case "SET_PULL_FREQUENCY_SELECT_OPTIONS":
            return {
                ...state,
                pullFrequencySelectOptions:
                    action.payload.pullFrequencySelectOptions
            };
        case "SET_FREQUENCY_MODE_SELECT_OPTIONS":
            return {
                ...state,
                frequencyModeSelectOptions:
                    action.payload.frequencyModeSelectOptions
            };
        case "SET_TAG_REQUEST_DATA":
            return {
                ...state,
                tagRequest: action.payload.tagRequest
            };
        case "SET_REQUEST_LOG_HISTORY_DATA":
            return {
                ...state,
                requestLogHistory: action.payload.requestLogHistory
            };

        case "SET_TAG_LOG_HISTORY_DATA":
            return {
                ...state,
                tagLogHistory: action.payload.tagLogHistory
            };
        case "SET_NEW_TAG_DATA":
            return {
                ...state,
                newTag: action.payload.newTag
            };
        case "SET_CONFIRM_ALL":
            return {
                ...state,
                confirmAll: action.payload.confirmAll
            };
        case "SET_REMOVED_TAGS_SWITCH_STATUS":
            return {
                ...state,
                removedTagsSwitchStatus: action.payload.removedTagsSwitchStatus
            };
        case "SET_UPLOADED_FILE_NAME":
            return {
                ...state,
                uploadedFileName: action.payload.uploadedFileName
            };
        case "SET_SELECTED_SITE":
            return { ...state, selectedSite: action.payload.selectedSite };
        case "SET_SELECTED_SOURCE":
            return { ...state, selectedSource: action.payload.selectedSource };
        case "SET_SELECTED_TAG_NAME":
            return {
                ...state,
                selectedTagName: action.payload.selectedTagName
            };
        case "SET_SCROLL_TO_ELEMENT_ID":
            return {
                ...state,
                scrollToElementId: action.payload.scrollToElementId
            };
        default:
            return state;
    }
};

// create a provider component
export const TagManagementProvider = ({
    children
}: TagManagementProviderProps) => {
    const [state, dispatch] = useReducer(TagManagementReducer, initialState);

    return (
        <TagManagementContext.Provider value={{ state, dispatch }}>
            {children}
        </TagManagementContext.Provider>
    );
};

export const useTagManagement = () => useContext(TagManagementContext);
