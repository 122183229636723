import config from "@config";
import { LogLevel, log } from "@cpchem/logging";
import {
    ApiResponse,
    FetchInterceptorService,
    FetchServiceKey,
    ServiceLocator
} from "@services";
import {
    errorResponseDictionary,
    errorResponseValue
} from "@services/api-response";
import { FetchOptionMethod, FetchOptions } from "@services/fetch-interfaces";
import {
    buildGetIngestedTagsRequestURL,
    buildGetMyApprovalsRequestURL,
    buildGetMyRequestsRequestURL,
    buildSearchIngestedTagsRequestURL,
    buildSearchMyApprovalsRequestURL,
    buildSearchMyRequestsRequestURL
} from "@utilities/api/helper";
import {
    CreateNewTagsAPIResponse,
    GetIngestedTagsAPIRequest,
    GetMyApprovalsAPIRequest,
    GetMyRequestsAPIRequest,
    HermesService,
    LogHistoryAPIResponse,
    RequestTagAPIResponse,
    SearchIngestedTagsAPIRequest,
    SearchMyApprovalsAPIRequest,
    SearchMyRequestsAPIRequest,
    TagActionsAPIResponse
} from "./interface";
import {
    IngestedTagsData,
    MyApprovalsData,
    MyRequestsData,
    Tag,
    TagRequest,
    UpdateTag
} from "./model";

const hermesResponseDictionary: errorResponseValue = {
    ...errorResponseDictionary
};

function defaultErrorResponse(response: Response) {
    log(
        `Unknown error when attempting to retrieve data. Status: ${response.statusText}`,
        LogLevel.ERROR
    );
    return {
        error: response.statusText
    };
}

export class HermesServiceImplementation implements HermesService {
    private readonly injestedTagsBase = config.api.ingestedTags.url;
    private readonly injestedTagsScopes = config.api.ingestedTags.scopes;
    private readonly tagFiltersBase = config.api.filters.url;
    private readonly tagMetadataBase = config.api.tagMetadata.url;
    private readonly lookupsBase = config.api.lookups.url;
    private readonly tagFiltersScopes = config.api.filters.scopes;
    private readonly myRequestsBase = config.api.myRequests.url;
    private readonly myRequestsScopes = config.api.myRequests.scopes;
    private readonly tagMetadataScopes = config.api.tagMetadata.scopes;
    private readonly lookupsScopes = config.api.lookups.scopes;
    private readonly myRequestTagBase = config.api.myRequestTag.url;
    private readonly myRequestTagScopes = config.api.myRequestTag.scopes;
    private readonly myLogHistoryBase = config.api.logHistory.url;
    private readonly myLogHistoryScopes = config.api.logHistory.scopes;

    interceptor: FetchInterceptorService;
    constructor() {
        this.interceptor =
            ServiceLocator.get<FetchInterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        scopes: string[],
        method: FetchOptionMethod,
        body?: string
    ): Promise<FetchOptions> {
        return await this.interceptor.getFetchOptionsAsync(
            scopes,
            method,
            body
        );
    }

    async GetIngestedTags(
        ingestedTagsRequest: GetIngestedTagsAPIRequest
    ): Promise<ApiResponse<IngestedTagsData>> {
        const uri = buildGetIngestedTagsRequestURL(
            this.injestedTagsBase,
            ingestedTagsRequest
        );
        const options = await this.ensureFetchOptionsAsync(
            this.injestedTagsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async SearchIngestedTags(
        searchTagsRequest: SearchIngestedTagsAPIRequest
    ): Promise<ApiResponse<IngestedTagsData>> {
        const uri = buildSearchIngestedTagsRequestURL(
            this.injestedTagsBase,
            searchTagsRequest
        );
        const options = await this.ensureFetchOptionsAsync(
            this.injestedTagsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetFilterItemsForFilterID(
        filterID: string
    ): Promise<ApiResponse<string[]>> {
        const uri = `${this.tagFiltersBase}/${filterID}`;
        const options = await this.ensureFetchOptionsAsync(
            this.tagFiltersScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetMyRequests(
        myRequestsRequest: GetMyRequestsAPIRequest
    ): Promise<ApiResponse<MyRequestsData>> {
        const uri = buildGetMyRequestsRequestURL(
            this.myRequestsBase,
            myRequestsRequest
        );
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetMyApprovals(
        myApprovalsRequest: GetMyApprovalsAPIRequest
    ): Promise<ApiResponse<MyApprovalsData>> {
        const uri = buildGetMyApprovalsRequestURL(
            this.myRequestsBase,
            myApprovalsRequest
        );
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async SearchMyRequests(
        searchRequest: SearchMyRequestsAPIRequest
    ): Promise<ApiResponse<MyRequestsData>> {
        const uri = buildSearchMyRequestsRequestURL(
            this.myRequestsBase,
            searchRequest
        );
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async SearchMyApprovals(
        searchApproval: SearchMyApprovalsAPIRequest
    ): Promise<ApiResponse<MyApprovalsData>> {
        const uri = buildSearchMyApprovalsRequestURL(
            this.myRequestsBase,
            searchApproval
        );
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetNewTagMetadata(newTag: Tag): Promise<ApiResponse<Tag>> {
        const encodedTagName = newTag.tagName
            ? encodeURIComponent(newTag.tagName)
            : newTag.tagName;

        const uri = `${this.tagMetadataBase}/${newTag.site}/${newTag.source}/${encodedTagName}`;
        const options = await this.ensureFetchOptionsAsync(
            this.tagMetadataScopes,
            "GET"
        );
        const res = await fetch(uri, options);
        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            throw hermesResponseDictionary[res.status]();
        }
        throw defaultErrorResponse(res);
    }

    async GetSiteMetadata(): Promise<ApiResponse<string[]>> {
        const uri = `${this.tagMetadataBase}/sites`;
        const options = await this.ensureFetchOptionsAsync(
            this.tagMetadataScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetSourceSystemMetadata(
        site: string
    ): Promise<ApiResponse<string[]>> {
        const uri = `${this.tagMetadataBase}/sources?site=${site}`;
        const options = await this.ensureFetchOptionsAsync(
            this.tagMetadataScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetTagNameMetadata(
        site: string,
        source: string
    ): Promise<ApiResponse<string[]>> {
        const uri = `${this.tagMetadataBase}/tags?site=${site}&source=${source}`;
        const options = await this.ensureFetchOptionsAsync(
            this.tagMetadataScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async FilterTagNamesMetadata(
        site: string,
        source: string,
        filterTerm: string
    ): Promise<ApiResponse<string[]>> {
        const encodedFilterTerm = filterTerm
            ? encodeURIComponent(filterTerm)
            : filterTerm;
        console.log("EncodedFilterTerm:" + `${encodedFilterTerm}`);
        const uri = `${this.tagMetadataBase}/tags?site=${site}&source=${source}&filterTerm=${encodedFilterTerm}`;
        const options = await this.ensureFetchOptionsAsync(
            this.tagMetadataScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetLookupData(lookupType: string): Promise<ApiResponse<string[]>> {
        const uri = `${this.lookupsBase}/${lookupType}`;
        const options = await this.ensureFetchOptionsAsync(
            this.lookupsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async CreateNewTagsRequest(
        tagRequest: TagRequest
    ): Promise<CreateNewTagsAPIResponse> {
        const uri = `${this.myRequestsBase}`;
        const tagRequestJSON = JSON.stringify(tagRequest);
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "POST",
            tagRequestJSON
        );

        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async ValidateAndGetTagsMetadata(
        site: string | undefined,
        source: string | undefined,
        tags: string[]
    ): Promise<TagActionsAPIResponse> {
        const uri = `${this.tagMetadataBase}/Validation?site=${site}&source=${source}`;
        const tagsJSON = JSON.stringify(tags);
        const options = await this.ensureFetchOptionsAsync(
            this.tagMetadataScopes,
            "POST",
            tagsJSON
        );

        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async GetRequest(
        requestId: number
    ): Promise<ApiResponse<RequestTagAPIResponse>> {
        const uri = `${this.myRequestsBase}/${requestId}`;
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async RemoveTag(
        requestId: number,
        tagId: number,
        status: string,
        reasonForChange: string
    ): Promise<TagActionsAPIResponse> {
        const uri = `${this.myRequestTagBase}/${requestId}/${tagId}?action=${status}`;
        const changeReason = JSON.stringify(reasonForChange);
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestTagScopes,
            "POST",
            changeReason
        );
        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async UpdateTag(updateTag: UpdateTag): Promise<TagActionsAPIResponse> {
        const uri = `${this.myRequestTagBase}`;
        const tagJSON = JSON.stringify(updateTag);
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestTagScopes,
            "PATCH",
            tagJSON
        );
        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async ChangeTagStatus(
        requestId: number,
        tagId: number,
        status: string
    ): Promise<TagActionsAPIResponse> {
        const uri = `${this.myRequestTagBase}/${requestId}/${tagId}?action=${status}`;
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestTagScopes,
            "PATCH"
        );
        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async ConfirmAllTags(
        requestId: number,
        status: string
    ): Promise<TagActionsAPIResponse> {
        const uri = `${this.myRequestsBase}/${requestId}?action=${status}`;
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "PATCH"
        );
        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async UpdateRequestByAction(
        requestId: number,
        action: string,
        requestBody: string | null
    ): Promise<TagActionsAPIResponse> {
        const uri = `${this.myRequestsBase}/${requestId}?action=${action}`;
        if (requestBody === null) {
            requestBody = "";
        }
        const requestJson = JSON.stringify(requestBody);
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "PATCH",
            requestJson
        );
        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }

    async GetRequestLogHistory(
        requestId: number
    ): Promise<ApiResponse<LogHistoryAPIResponse>> {
        const uri = `${this.myLogHistoryBase}/${requestId}`;
        const options = await this.ensureFetchOptionsAsync(
            this.myLogHistoryScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async GetTagLogHistory(
        requestId: number,
        tagId: number
    ): Promise<ApiResponse<LogHistoryAPIResponse>> {
        const uri = `${this.myLogHistoryBase}/${requestId}/${tagId}`;
        const options = await this.ensureFetchOptionsAsync(
            this.myLogHistoryScopes,
            "GET"
        );
        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (hermesResponseDictionary[res.status]) {
            return hermesResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async UpdateTagByAction(
        requestId: number,
        tagId: number,
        action: string,
        requestBody: string | null
    ): Promise<TagActionsAPIResponse> {
        const uri = `${this.myRequestTagBase}/${requestId}/${tagId}?action=${action}`;
        if (requestBody === null) {
            requestBody = "";
        }
        const requestJson = JSON.stringify(requestBody);
        const options = await this.ensureFetchOptionsAsync(
            this.myRequestsScopes,
            "POST",
            requestJson
        );
        const res = await fetch(uri, options);
        const json = await res.json();
        return json;
    }
}
