import { useAuth } from "@cpchem/azure-auth-react";
import { faArrowAltRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HistoryLogViewTestIds } from "@pages/tag-management-page/test-ids";
import { Activity, Change } from "@services/hermes/model";
import React, { useEffect, useState } from "react";
import "./styles.scss";

interface LogCommentViewProps {
    activity: Activity;
}
export const LogCommentView = ({ activity }: LogCommentViewProps) => {
    const { currentAccount } = useAuth({});
    const [activityText, setActivityText] = useState("");
    const [changeText, setChangeText] = useState("");
    const [reasonForChange, setReasonForChange] = useState("");
    const fName = currentAccount?.name?.split(",")[1] || "";
    const lName = currentAccount?.name?.split(",")[0] || "";
    const changeLog = activity.log;
    const [changes, setChanges] = useState<Change[]>([]);

    useEffect(() => {
        if (activity.logType === "confirmed") {
            setActivityText("made a change.");
            setChangeText("Tag status set to confirmed.");
        } else if (activity.logType === "pending") {
            if (typeof changeLog === "string" && changeLog === "") {
                setActivityText("made a change.");
                setChangeText("Tag status set to pending.");
            } else if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("made a change.");
                setReasonForChange(changeLog.ReasonForChange);
            }
        } else if (activity.logType === "modified") {
            if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("made a change.");
                setReasonForChange(changeLog.ReasonForChange);
                if (changeLog.TagUpdates !== null)
                    setChanges(changeLog.TagUpdates);
                console.log(changeLog.TagUpdates);
            }
        } else if (activity.logType === "removed") {
            if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("made a change.");
                setReasonForChange(changeLog.ReasonForChange);
            }
        } else if (activity.logType === "commented") {
            if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("left a Note.");
                setReasonForChange(changeLog.ReasonForChange);
            }
        }
    }, [activity, changeLog, changes, reasonForChange]);

    return (
        <>
            <div
                className="change-submitter"
                data-testid={HistoryLogViewTestIds.ChangeSubmitter}
            >
                {fName} {lName}
            </div>
            {activity.logType !== "commented" && (
                <div
                    className="activity"
                    data-testid={HistoryLogViewTestIds.Activity}
                >
                    <div
                        className="activity-text"
                        data-testid={HistoryLogViewTestIds.ActivityText}
                    >
                        {activityText}
                    </div>
                    <div
                        className="activity-changes"
                        data-testid={HistoryLogViewTestIds.ActivityChanges}
                    >
                        {changes.length > 0 && (
                            <ul
                                data-testid={
                                    HistoryLogViewTestIds.ActivityChangesList
                                }
                            >
                                Changes:
                                {changes.map((change) => {
                                    return (
                                        <li key={change.Name}>
                                            <div
                                                className="change"
                                                data-testid={
                                                    HistoryLogViewTestIds.Change
                                                }
                                            >
                                                <div
                                                    className="change-name"
                                                    data-testid={
                                                        HistoryLogViewTestIds.ChangeName
                                                    }
                                                >
                                                    {change.Name}
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faArrowAltRight}
                                                    size="sm"
                                                    data-testid=""
                                                />
                                                <div
                                                    className="change-from"
                                                    data-testid={
                                                        HistoryLogViewTestIds.ChangeFrom
                                                    }
                                                >
                                                    {change.From}
                                                </div>
                                                <div
                                                    className="change-to"
                                                    data-testid={
                                                        HistoryLogViewTestIds.ChangeTo
                                                    }
                                                >
                                                    {change.To}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                    <div className="change-text">{changeText}</div>
                </div>
            )}
            <div className="reason-for-change">
                {reasonForChange}
                {"."}
            </div>
        </>
    );
};
