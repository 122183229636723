import { ApprovalTagReviewDisplay } from "@components/display/approvals-display/approval-tag-review/approval-tag-review-display";
import { RequestTagReviewDisplay } from "@components/display/request-displays/request-tag-review/request-tag-review-display";
import {
    Button,
    Card,
    Divider,
    Modal,
    ModalContent,
    TextField,
    useToast
} from "@cpchem/covalence-ui";
import { faCircleCheck, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { ApprovalPreviewTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import {
    InitialFilterState,
    LogHistoryAPIResponse,
    RequestTagAPIResponse
} from "@services/hermes/interface";
import { Activity, Log, TagRequest } from "@services/hermes/model";
import {
    FREQUENCY_MODE_FILTER,
    FilterType,
    PULL_FREQUENCY_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { capitalizeFirstLetter } from "@utilities/capitalize-first-letter";
import { buildSelectOptionsList } from "@utilities/filters/build-select-options-list";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useService } from "../../../../../service-provider";
import { HistoryLogView } from "../../history-log-view";
import { RequestHistoryView } from "../../request-history-view";
import { RequestTitleView } from "../../request-title-view";
import "./styles.scss";

export function ApprovalPreview(): JSX.Element {
    const { requestId } = useParams();
    const { state, dispatch } = useTagManagement();
    const { tagRequest, tagLogHistory } = state;
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { isLoading, onSetLoading } = useTagManagementContext();
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [tagActivityModalVisible, setTagActivityModalVisible] =
        useState(false);
    const [requestAction, setRequestAction] = useState("Updated");
    const [tagNameForActivity, setTagNameForActivity] = useState("");
    const [tagIdForActivity, setTagIdForActivity] = useState<number>(0);
    const [tagNote, setTagNote] = useState("");
    const { createToast } = useToast();
    const rowRefs = React.useRef<Record<string, HTMLTableRowElement>>({});
    const [actionRowId, setActionRowId] = useState<string | null>(null);

    let approveRequestPanelText = "";
    let showTagReviewTable;
    const pendingTagRequestCount = tagRequest.items?.filter(
        (tag) => tag.tagStatus?.toLowerCase() === "pending"
    ).length;

    if (pendingTagRequestCount !== 0) {
        approveRequestPanelText = ` ${pendingTagRequestCount} Tags need your attention before approving the Request`;
    } else {
        approveRequestPanelText = `All Tags have been confirmed`;
    }

    const requestCompleteOrClosed =
        tagRequest.requestStatus?.toLowerCase() === "complete" ||
        tagRequest.requestStatus?.toLowerCase() === "closed";

    async function getRequestDetails() {
        try {
            onSetLoading(true);
            const response = await hermesService.GetRequest(Number(requestId));
            if (response.data) {
                const requestActionsResponse =
                    response.data as RequestTagAPIResponse;
                const results = requestActionsResponse.data as TagRequest;
                setTagRequestDataState(results);
            }
        } catch (error) {
            console.error(error);
        } finally {
            onSetLoading(false);
        }
    }

    function setTagRequestDataState(updatedTagRequest: TagRequest) {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: { ...updatedTagRequest }
            }
        });
    }

    async function GetRequestLogHistoryDetails() {
        try {
            const response = await hermesService.GetRequestLogHistory(
                Number(requestId)
            );
            if (response.data) {
                const requestActionsResponse =
                    response.data as LogHistoryAPIResponse;

                const results: Activity[] = requestActionsResponse.data.map(
                    (item) => ({
                        ...item,
                        log: JSON.parse(item.log)
                    })
                );
                setRequestLogHistoryDataState(results);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function setRequestLogHistoryDataState(requestLogHistory: Activity[]) {
        dispatch({
            type: "SET_REQUEST_LOG_HISTORY_DATA",
            payload: {
                requestLogHistory: requestLogHistory
            }
        });
    }

    async function getLookupItems(filterType: FilterType) {
        try {
            const filterResponse = await hermesService.GetLookupData(
                filterType.ID
            );

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setSelectOptionsState(filterType.TITLE, data);
            }
        } catch (error) {
            setSelectOptionsState(
                filterType.TITLE,
                InitialFilterState.filterItems
            );
        }
    }

    async function setSelectOptionsState(filterName: string, data: string[]) {
        const selectOptionActions = {
            [PULL_FREQUENCY_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_PULL_FREQUENCY_SELECT_OPTIONS",
                    payload: {
                        pullFrequencySelectOptions: buildSelectOptionsList(
                            PULL_FREQUENCY_FILTER.TITLE,
                            data
                        )
                    }
                });
            },
            [FREQUENCY_MODE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_FREQUENCY_MODE_SELECT_OPTIONS",
                    payload: {
                        frequencyModeSelectOptions: buildSelectOptionsList(
                            FREQUENCY_MODE_FILTER.TITLE,
                            data
                        )
                    }
                });
            }
        };

        const action = selectOptionActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    const handleRemoveTagRow = async (
        id: string,
        tagName: string | undefined,
        tagStatus: string | null,
        reasonForChange: string
    ) => {
        try {
            onSetLoading(true);
            if (tagStatus) {
                const tagReviewed = tagRequest.items.find(
                    (tag) => tag.tagName === tagName
                );
                if (tagReviewed) {
                    await hermesService
                        .RemoveTag(
                            tagRequest.id,
                            tagReviewed.id,
                            tagStatus,
                            reasonForChange
                        )
                        .then(async (removeTagResponse) => {
                            if (removeTagResponse.code === 200) {
                                await hermesService
                                    .GetRequest(Number(requestId))
                                    .then((getRequestDetailsResponse) => {
                                        if (getRequestDetailsResponse.data) {
                                            const tagActionsResponse =
                                                getRequestDetailsResponse.data as RequestTagAPIResponse;
                                            const results =
                                                tagActionsResponse.data as TagRequest;
                                            setTagRequestDataState(results);

                                            createToast(
                                                `Tag ${tagName} status set to ${tagStatus}`,
                                                {
                                                    id: `tag-${tagName}-status-${tagStatus}`,
                                                    severity: "info"
                                                }
                                            );
                                        }
                                    });
                            } else {
                                const errorMessage = removeTagResponse.message
                                    ? removeTagResponse.message
                                    : "Unknown error occurred, please contact EHFIL Dev Support Group";
                                throw new Error(errorMessage);
                            }
                        });
                }
            }
            setActionRowId(id);
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} removal unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-not-removed`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 10000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    const handleUpdateTagRow = async (
        id: string,
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined,
        frequencyMode?: string | undefined
    ) => {
        try {
            onSetLoading(true);
            const tagReviewed = tagRequest.items.find(
                (tag) => tag.tagName === tagName
            );

            if (tagReviewed) {
                const updatedTag = {
                    ...tagReviewed,
                    pullFrequency: pullFrequency,
                    frequencyMode: frequencyMode,
                    comments: comments
                };

                await hermesService
                    .UpdateTag({
                        ...updatedTag,
                        ...{ requestId: tagRequest.id }
                    })
                    .then(async (updateTagResponse) => {
                        if (updateTagResponse.code === 200) {
                            await hermesService
                                .GetRequest(Number(requestId))
                                .then(async (getRequestDetailsResponse) => {
                                    if (getRequestDetailsResponse.data) {
                                        const tagActionsResponse =
                                            getRequestDetailsResponse.data as RequestTagAPIResponse;
                                        const results =
                                            tagActionsResponse.data as TagRequest;
                                        setTagRequestDataState(results);

                                        createToast(
                                            `Tag ${tagName} updated successfully!`,
                                            {
                                                id: `tag-${tagName}-updated`,
                                                severity: "info"
                                            }
                                        );
                                    }
                                });
                        } else {
                            const errorMessage = updateTagResponse.message
                                ? updateTagResponse.message
                                : "Unknown error occurred, please contact EHFIL Dev Support Group";
                            throw new Error(errorMessage);
                        }
                    });
            } else {
                throw new Error(
                    "Unknown error occurred, please contact EHFIL Dev Support Group"
                );
            }
            setActionRowId(id);
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} update unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-not-updated`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 10000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    const handleChangeTagStatus = async (
        id: string,
        tagName: string | undefined,
        tagStatus: string | null
    ) => {
        try {
            onSetLoading(true);
            if (tagStatus) {
                const tagReviewed = tagRequest.items.find(
                    (tag) => tag.tagName === tagName
                );
                let updatedStatus = "";
                if (tagStatus.toLowerCase() === "pending") {
                    updatedStatus = "confirmed";
                } else if (tagStatus.toLowerCase() === "confirmed") {
                    updatedStatus = "pending";
                } else if (tagStatus.toLowerCase() === "removed") {
                    updatedStatus = "pending";
                } else {
                    updatedStatus = "pending";
                }
                if (tagReviewed) {
                    await hermesService
                        .ChangeTagStatus(
                            tagRequest.id,
                            tagReviewed.id,
                            updatedStatus
                        )
                        .then(async (changeTagStatusResponse) => {
                            if (changeTagStatusResponse.code === 200) {
                                await hermesService
                                    .GetRequest(Number(requestId))
                                    .then((getRequestDetailsResponse) => {
                                        if (getRequestDetailsResponse.data) {
                                            const tagActionsResponse =
                                                getRequestDetailsResponse.data as RequestTagAPIResponse;
                                            const results =
                                                tagActionsResponse.data as TagRequest;
                                            setTagRequestDataState(results);

                                            createToast(
                                                `Tag ${tagName} status set to ${updatedStatus}`,
                                                {
                                                    id: `tag-${tagName}-status-${updatedStatus}`,
                                                    severity: "info"
                                                }
                                            );
                                        }
                                    });
                            } else {
                                const errorMessage =
                                    changeTagStatusResponse.message
                                        ? changeTagStatusResponse.message
                                        : "Unknown error occurred, please contact EHFIL Dev Support Group";
                                throw new Error(errorMessage);
                            }
                        });
                } else {
                    throw new Error(
                        "Unknown error occurred, please contact EHFIL Dev Support Group"
                    );
                }
            }
            setActionRowId(id);
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} status change unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-status-not-changed`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 10000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    const handleConfirmAllTagRows = async (confirmAll: string) => {
        try {
            onSetLoading(true);
            const previousStatus =
                confirmAll.toLowerCase() === "pending"
                    ? "confirmed"
                    : "pending";
            await hermesService
                .ConfirmAllTags(tagRequest.id, confirmAll)
                .then(async (confirmAllTagsResponse) => {
                    if (confirmAllTagsResponse.code === 200) {
                        await hermesService
                            .GetRequest(Number(requestId))
                            .then((getRequestDetailsResponse) => {
                                if (getRequestDetailsResponse.data) {
                                    const tagActionsResponse =
                                        getRequestDetailsResponse.data as RequestTagAPIResponse;
                                    const results =
                                        tagActionsResponse.data as TagRequest;
                                    setTagRequestDataState(results);

                                    createToast(
                                        `All ${previousStatus} Tags status set to ${confirmAll}`,
                                        {
                                            id: `all-tags-set-to-${previousStatus}`,
                                            severity: "info"
                                        }
                                    );
                                }
                            });
                    } else {
                        const errorMessage = confirmAllTagsResponse.message
                            ? confirmAllTagsResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(`Confirm All action unsuccessful. ${error.message}`, {
                id: `confirm-all-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } finally {
            onSetLoading(false);
        }
    };

    const handleTagActivityModalOpen = async (
        tagId: number,
        tagName: string | undefined
    ) => {
        try {
            onSetLoading(true);
            if (tagName) {
                await hermesService
                    .GetTagLogHistory(tagRequest.id, tagId)
                    .then((tagLogResponse) => {
                        if (tagLogResponse.data) {
                            const tagActionsResponse =
                                tagLogResponse.data as LogHistoryAPIResponse;
                            const results: Activity[] =
                                tagActionsResponse.data.map((item) => {
                                    let parsedLog;
                                    try {
                                        parsedLog = item.log
                                            ? JSON.parse(item.log)
                                            : "";
                                    } catch (e) {
                                        // if the log is already an object or not a valid JSON,
                                        // then just use the log as is
                                        parsedLog = item.log || "";
                                    }
                                    return {
                                        ...item,
                                        log: parsedLog
                                    };
                                });

                            const sortedResults = results.sort(
                                (a, b) =>
                                    new Date(b.commentedDate).getTime() -
                                    new Date(a.commentedDate).getTime()
                            );

                            setTagLogHistoryDataState(sortedResults);
                            setTagIdForActivity(tagId);
                            setTagNameForActivity(tagName);
                            setTagActivityModalVisible(true);
                        }
                    });
            }
        } catch (tagLogProcessingError) {
            const error = tagLogProcessingError as Error;
            createToast(
                `Could not retrieve ${tagName} activity. ${error.message}`,
                {
                    id: `tag-${tagName}-activity-error`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 10000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    function setTagLogHistoryDataState(tagLogHistory: Activity[]) {
        dispatch({
            type: "SET_TAG_LOG_HISTORY_DATA",
            payload: {
                tagLogHistory: tagLogHistory
            }
        });
    }

    function onTagActivityModalClosed() {
        setTagActivityModalVisible(false);
    }

    const addNoteAction = (tagId: number, action: string, note: string) => {
        if (note === "") {
            displayNoteRequiredToast();
        } else {
            addTagNote(tagId, action, note);
            setTagNote("");
        }
    };

    async function addTagNote(tagId: number, action: string, note: string) {
        try {
            onSetLoading(true);
            await hermesService
                .UpdateTagByAction(tagRequest.id, tagId, action, note)
                .then(async (updateTagResponse) => {
                    if (updateTagResponse.code === 200) {
                        await hermesService
                            .GetRequest(Number(requestId))
                            .then(async (getRequestDetailsResponse) => {
                                let results: TagRequest;
                                if (getRequestDetailsResponse.data) {
                                    const tagActionsResponse =
                                        getRequestDetailsResponse.data as RequestTagAPIResponse;
                                    results =
                                        tagActionsResponse.data as TagRequest;
                                    setTagRequestDataState(results);
                                }

                                await hermesService
                                    .GetTagLogHistory(tagRequest.id, tagId)
                                    .then((tagLogResponse) => {
                                        if (tagLogResponse.data) {
                                            const tagActionsResponse =
                                                tagLogResponse.data as LogHistoryAPIResponse;
                                            const results: Activity[] =
                                                tagActionsResponse.data.map(
                                                    (item) => {
                                                        let parsedLog;
                                                        try {
                                                            parsedLog = item.log
                                                                ? JSON.parse(
                                                                      item.log
                                                                  )
                                                                : "";
                                                        } catch (e) {
                                                            // if the log is already an object or not a valid JSON,
                                                            // then just use the log as is
                                                            parsedLog =
                                                                item.log || "";
                                                        }
                                                        return {
                                                            ...item,
                                                            log: parsedLog
                                                        };
                                                    }
                                                );

                                            const sortedResults = results.sort(
                                                (a, b) =>
                                                    new Date(
                                                        b.commentedDate
                                                    ).getTime() -
                                                    new Date(
                                                        a.commentedDate
                                                    ).getTime()
                                            );

                                            setTagLogHistoryDataState(
                                                sortedResults
                                            );
                                            createSuccessToastByAction(action);
                                        }
                                    });
                            });
                    } else {
                        const errorMessage = updateTagResponse.message
                            ? updateTagResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (tagProcessing) {
            const error = tagProcessing as Error;
            createErrorToastByAction(error, action);
        } finally {
            onSetLoading(false);
        }
    }

    function displayNoteRequiredToast() {
        createToast(`Please enter a Note!`, {
            id: `enter-note-toast`,
            severity: "danger",
            autoDismiss: false,
            autoDismissDelay: 10000
        });
    }

    const onTagNoteEntered = (newValue: string) => {
        setTagNote(newValue);
    };

    const successModalText =
        requestAction === "Approved" ? "Approval" : "Close";

    async function UpdateRequestByAction(
        action: string,
        requestComment: string | null
    ) {
        try {
            onSetLoading(true);
            await hermesService
                .UpdateRequestByAction(tagRequest.id, action, requestComment)
                .then(async (updateRequestResponse) => {
                    if (updateRequestResponse.code === 200) {
                        await hermesService
                            .GetRequest(Number(requestId))
                            .then(async (getRequestDetailsResponse) => {
                                let results: TagRequest;
                                if (getRequestDetailsResponse.data) {
                                    const tagActionsResponse =
                                        getRequestDetailsResponse.data as RequestTagAPIResponse;
                                    results =
                                        tagActionsResponse.data as TagRequest;
                                    setTagRequestDataState(results);
                                }

                                await hermesService
                                    .GetRequestLogHistory(Number(requestId))
                                    .then((requestLogResponse) => {
                                        const requestActionsResponse =
                                            requestLogResponse.data as LogHistoryAPIResponse;

                                        const results: Activity[] =
                                            requestActionsResponse.data.map(
                                                (item) => ({
                                                    ...item,
                                                    log: JSON.parse(item.log)
                                                })
                                            );
                                        setRequestLogHistoryDataState(results);
                                    });
                                showModalOrToast(action);
                            });
                    } else {
                        const errorMessage = updateRequestResponse.message
                            ? updateRequestResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (requestProcessing) {
            const error = requestProcessing as Error;
            createErrorToastByAction(error, action);
        } finally {
            onSetLoading(false);
        }
    }

    function showModalOrToast(action: string) {
        if (action === "approved") {
            setRequestAction("Approved");
            setSuccessModalVisible(true);
        } else if (action === "closed") {
            setRequestAction("Closed");
            setSuccessModalVisible(true);
        } else {
            setRequestAction("Updated");
            createSuccessToastByAction(action);
        }
    }

    function createErrorToastByAction(error: Error, action: string) {
        if (action === "approved") {
            createToast(`Request not approved. ${error.message}`, {
                id: `request-approval-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } else if (action === "closed") {
            createToast(`Request not closed. ${error.message}`, {
                id: `request-closed-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } else {
            createToast(`Request not updated. ${error.message}`, {
                id: `request-update-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        }
    }

    function createSuccessToastByAction(action: string) {
        if (action === "commented") {
            createToast(`New comment posted`, {
                id: `request-update-action-successful`,
                severity: "info",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        } else {
            createToast(`Request updated successfully!`, {
                id: `request-update-action-successful`,
                severity: "success",
                autoDismiss: false,
                autoDismissDelay: 10000
            });
        }
    }

    function onSuccessModalClosed() {
        setSuccessModalVisible(false);
    }

    async function getAllInitialLookupItems() {
        getLookupItems(PULL_FREQUENCY_FILTER);
        getLookupItems(FREQUENCY_MODE_FILTER);
    }

    useEffect(() => {
        if (actionRowId !== null && rowRefs.current[actionRowId]) {
            rowRefs.current[actionRowId].scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        }
    }, [tagRequest, actionRowId]);

    useEffect(() => {
        getRequestDetails();
        GetRequestLogHistoryDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, dispatch]);

    useEffect(() => {
        getAllInitialLookupItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.confirmAll?.toLowerCase() === "confirmed")
            handleConfirmAllTagRows(state.confirmAll);
        else if (state.confirmAll?.toLowerCase() === "pending")
            handleConfirmAllTagRows(state.confirmAll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.confirmAll]);

    const disableApprovalButton =
        approveRequestPanelText !== "All Tags have been confirmed";

    if (!isLoading && !requestCompleteOrClosed) {
        showTagReviewTable = (
            <Card testId={ApprovalPreviewTestIds.ApprovalTagsReviewDisplayCard}>
                <ApprovalTagReviewDisplay
                    rowRefs={rowRefs}
                    removeTagRow={handleRemoveTagRow}
                    updateTagRow={handleUpdateTagRow}
                    changeTagRowStatus={handleChangeTagStatus}
                    tagActivityModalOpen={handleTagActivityModalOpen}
                />
            </Card>
        );
    } else if (!isLoading && requestCompleteOrClosed) {
        showTagReviewTable = (
            <Card testId={ApprovalPreviewTestIds.RequestTagsReviewDisplayCard}>
                <RequestTagReviewDisplay
                    rowRefs={rowRefs}
                    updateTagRow={handleUpdateTagRow}
                    tagActivityModalOpen={handleTagActivityModalOpen}
                />
            </Card>
        );
    }
    return (
        <div className="approval-preview">
            <Card testId={ApprovalPreviewTestIds.RequestTitleViewCard}>
                <RequestTitleView
                    title={tagRequest.requestName}
                    status={capitalizeFirstLetter(tagRequest.requestStatus)}
                    lastUpdated="Last updated 20 mins ago"
                />
            </Card>
            {showTagReviewTable}
            <Card testId={ApprovalPreviewTestIds.RequestHistoryView}>
                <RequestHistoryView
                    closeRequest={(action: string, requestComment: string) =>
                        UpdateRequestByAction(action, requestComment)
                    }
                    saveComment={(action: string, requestComment: string) =>
                        UpdateRequestByAction(action, requestComment)
                    }
                    hideRequestComment={requestCompleteOrClosed}
                />
            </Card>
            {!requestCompleteOrClosed && (
                <Card testId={ApprovalPreviewTestIds.ApproveRequestPanelCard}>
                    <div className="approve-request-panel">
                        <div className="approve-request-panel-container">
                            {disableApprovalButton && (
                                <FontAwesomeIcon
                                    icon={faWarning}
                                    className="approve-request-panel-warning-icon"
                                    size="xl"
                                />
                            )}
                            {!disableApprovalButton && (
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    className="approve-request-panel-ready-icon"
                                    size="xl"
                                />
                            )}
                            <p className="approve-request-panel-text">
                                {approveRequestPanelText}
                            </p>
                        </div>
                        <Button
                            variant="solid"
                            isDisabled={disableApprovalButton}
                            size="medium"
                            text="Approve Request"
                            className="approve-request-button"
                            color="success"
                            onClick={() =>
                                UpdateRequestByAction("approved", null)
                            }
                            testId={ApprovalPreviewTestIds.ApproveRequestButton}
                        />
                    </div>
                </Card>
            )}
            <Modal
                onRequestClose={onSuccessModalClosed}
                title="Success"
                isOpen={successModalVisible}
                className="success-modal"
                testId={ApprovalPreviewTestIds.SuccessModal}
            >
                <ModalContent
                    testId={ApprovalPreviewTestIds.SuccessModalContent}
                >
                    <div className="success-modal-question">
                        Request {successModalText} Successful!
                    </div>
                    <div className="success-modal-text">
                        {tagRequest.requestName} request was successfully{" "}
                        {requestAction}!
                    </div>
                </ModalContent>
            </Modal>
            <Modal
                onRequestClose={onTagActivityModalClosed}
                title={`${tagNameForActivity} Activity`}
                isOpen={tagActivityModalVisible}
                className="tag-activity-modal"
                testId={ApprovalPreviewTestIds.TagActivityModal}
            >
                <ModalContent
                    testId={ApprovalPreviewTestIds.TagActivityModalContent}
                >
                    <div className="tag-activity-modal-note">
                        <TextField
                            placeholder="Enter Note Here..."
                            onChange={onTagNoteEntered}
                            label="Add a Note..."
                            value={tagNote}
                            isMultiline
                            isResizable
                            testId={ApprovalPreviewTestIds.EnterTagNote}
                        />
                        <div className="add-note-panel-button">
                            <Button
                                variant="solid"
                                size="medium"
                                text="Add Note"
                                className="add-note"
                                color="primary"
                                onClick={() =>
                                    addNoteAction(
                                        tagIdForActivity,
                                        "commented",
                                        tagNote
                                    )
                                }
                                testId={ApprovalPreviewTestIds.AddNote}
                            />
                        </div>
                    </div>
                    <Divider className="tag-activity-divider" />
                    <div
                        className="tag-activity-title"
                        data-testid={ApprovalPreviewTestIds.HistoryLogTitle}
                    >
                        Tag Activity
                    </div>
                    <div className="tag-activity-modal-history">
                        <HistoryLogView changeLogHistory={tagLogHistory} />
                    </div>
                </ModalContent>
            </Modal>
        </div>
    );
}
